.container {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100%;

  .banner-container {
    background: linear-gradient(to right, #006ca3 0%, #225165 100%);
    width: 60%;
    display: grid;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;

    .logo {
      width: 641px;
    }

    .break {
      background-color: #ffffff;
      width: 90%;
    }

    .legal-text {
      color: #ffffff;
      font-size: 0.8em;
      width: 88%;
      margin: 0 auto;
      padding: 25px;
    }
  }

  .login-container {
    background-color: #f7f5f7;
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .login-field {
      background-color: #ffffff;
      border-radius: 6px;
      width: 300px;
    }

    .MuiInputBase-root > fieldset {
      border-color: #fff;
    }

    .MuiFormHelperText-root {
      background-color: #f7f5f7;
      margin: 0;
    }

    .login-button {
      height: 50px;
      width: 300px;
    }
  }
}
