.change-password-prompt {
  .MuiTextField-root {
    width: 300px;
  }

  .MuiInputBase-root > fieldset {
    border-color: #fff;
  }

  .MuiFormHelperText-root {
    margin: 0;
  }
}
